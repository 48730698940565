<template>
  <div class="vm-news-wrap">
    <div class="featured-news-wrap">
      <div class="vm-section vm-articles">
        <div class="featured-img d-flex align-end" v-if="article.photos !== ''"
             v-bind:style="{ 'background-image': 'url(' + article.photos + ')' }">
          <v-container>
            <img :src="article.photos" :alt="article.title" class="d-none">
            <h1>{{ article.title }}</h1>
          </v-container>
        </div>
        <div v-else class="featured-img  d-flex align-end"
             :style="{'background-image': 'url(' + require('@/assets/images/banner-default.jpg') + ')'}">
          <v-container>
            <h1>{{ article.title }}</h1>
          </v-container>
        </div>
        <v-container>
          <div class="article-content">
            <div class="post-intro" v-html="article.introduction"></div>
            <div class="post-body" v-html="article.body"></div>
          </div>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: 'VumaNews',
  metaInfo () {
    let pageTitle = this.article.title
    return {
      title: pageTitle,
      meta: [
        { name: 'description', content: this.article.introduction },
        { property: 'og:title', content: pageTitle },
        { property: 'og:site_name', content: 'Vumatel' },
        { property: 'og:type', content: 'website' },
      ],
    }
  },
  data: () => ({
    isShop: false,
    article: [],
    title: '',
  }),
  mounted () {
    $('.vuma-menu, .menu-overlay').removeClass('menu-show')
    this.scrollTop()

    let vue = this
    let slug = vue.$route.params.slug

    let cms_url = process.env.VUE_APP_CMS_HOST + '/post/' + slug + '.json'

    vue.axios.get(cms_url).then(response => {
      vue.article = response.data
      vue.title = vue.article.title
    })
  },
  methods: {
    scrollTop () {
      var target = 'html, body'
      var $target = $(target)

      $('html, body').stop().animate({
        'scrollTop': $target.offset().top,
      }, 500, 'swing')
    },
  },

}
</script>

<style lang="scss">
.news-page {
  padding-top: 0;

  .vuma-header {
    top: 0;
    background: transparent;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    a.vm-logo .vuma-logo-white {
      display: inline-block;
    }

    a.vm-logo .vuma-logo-pink {
      display: none;
    }

    &.fixed-top {
      .top-nav .v-btn--text {
        color: #fff;
      }

      a.vm-logo .vuma-logo-white {
        display: inline-block;
      }

      a.vm-logo .vuma-logo-pink {
        display: none;
      }
    }

  }

  #read-more-news {
    display: none;
  }

  &.article-page {
    .vm-section {
      padding-top: 0;
    }
  }
}

.vm-articles {
  .vuma-header {
    top: 0;
    background: #fff !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    // NEST
    a.vm-logo .vuma-logo-white {
      display: none;
    }

    a.vm-logo .vuma-logo-pink {
      display: inline-block;
    }

    .top-nav .v-btn--text {
      color: #EE008C;
    }

    &.fixed-top {
      background: #ff008e !important;

      .top-nav .v-btn--text {
        color: #fff;
      }

      a.vm-logo .vuma-logo-white {
        display: inline-block;
      }

      a.vm-logo .vuma-logo-pink {
        display: none;
      }
    }
  }

  .vm-articles-wrap {
    max-height: 700px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 3rem;
  }

  .row {
    border-bottom: 1px solid #E6E6E6;
    padding-bottom: 2rem;
    margin-bottom: 2rem;

    &:last-child {
      border-bottom: none;
    }
  }

  h3 {
    font-family: "Core Sans R 25", "Roboto", sans-serif !important;
    font-weight: inherit;
    font-size: 30px;
    margin-bottom: 3rem;
  }

  .article-content {
    .post-intro {
      font-size: 35px;
      color: #ff008e;
      font-family: "Core Sans R 35", "Roboto", sans-serif !important;
      margin-bottom: 2rem;
    }
  }

  .featured-img {
    width: 100%;
    height: 470px;
    background-color: #eee;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-bottom: 2rem;
    margin-bottom: 3rem;
    position: relative;

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 0;
      left: 0;
    }

    h1 {
      color: #fff;
      position: relative;
      font-size: 60px;
      line-height: 1;
    }
  }

}

@media screen and (max-width: 991px) {
  .vm-articles .featured-img .container {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@media screen and (max-width: 767px) {
  .vm-articles .featured-img .container,
  .vm-articles .container {
    padding-left: 25px;
    padding-right: 25px;
  }

  .vm-articles .featured-img {
    height: 370px;
  }
  .vm-articles .featured-img h1 {
    font-size: 42px;
  }
  .vm-articles .article-content .post-intro {
    font-size: 24px;
  }
}
</style>